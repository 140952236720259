export default {
  'slot1': "8512363840",
  'slot2': "6484966304",
  'slot3': "9453910426",
  'firebaseConfig': {
 apiKey: "AIzaSyAI5kNmRDW7RlaqrVjLbuvU0eKnRPT0Om4",
  authDomain: "news6-network.firebaseapp.com",
  projectId: "news6-network",
  storageBucket: "news6-network.appspot.com",
  messagingSenderId: "686138939945",
  appId: "1:686138939945:web:cc85b510feaf0bbc049a38",
  measurementId: "G-9JM5VQ520K"
  }
}